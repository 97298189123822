<template>
  <div class="loan-apply">
    <div class="main">
      <div class="title">{{ $t('chuang-ye-dai-kuan-shen-qing-tian-xie') }}</div>

      <el-form
        :model="form"
        ref="form"
        class="apply-form"
        label-position="left"
        :rules="formRule"
      >
        <el-form-item label="$t('shen-qing-ren')" prop="ShopName">
          <el-input
            v-model="form.ShopName"
            :placeholder="$t('qing-shu-ru-shen-qing-ren')"
          ></el-input>
        </el-form-item>
        <el-form-item label="$t('dai-kuan-qi-xian')" prop="ShopAddress">
          <el-select v-model="form.ShopAddress">
            <el-option
              v-for="(item, i) in timeList"
              :key="i"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="$t('shen-qing-jin-e')" prop="ShopCountry">
          <el-input
            v-model="form.ShopCountry"
            :placeholder="$t('4999-500000')"
          ></el-input>
        </el-form-item>
        <el-form-item label="$t('dai-kuan-li-shuai')" prop="percent">
          <el-input v-model="form.percent" disabled></el-input>
        </el-form-item>
        <el-form-item label="$t('fang-kuan-fang-shi')" prop="typeName">
          <el-input v-model="form.typeName" disabled></el-input>
        </el-form-item>
        <el-form-item label="$t('guo-ji')" prop="RealName">
          <el-input
            v-model="form.RealName"
            :placeholder="$t('qing-shu-ru-guo-ji')"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="$t('zheng-jian-hao-ma-hu-zhao-hao-ma')"
          prop="IdCard"
        >
          <el-input
            v-model="form.IdCard"
            :placeholder="$t('qing-shu-ru-zheng-jian-hao-ma-hu-zhao-hao-ma')"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="$t('zheng-jian-zhao-hu-zhao-shang-chuan')"
          required
          prop="IdCardFront"
        >
          <div class="flex-center-start upload-line">
            <div class="upload-box">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :action="uploadUrl"
                name="file"
                :headers="headers"
                :on-success="(file) => uploadSuccess(file, 'IdCardFront')"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="form.IdCardFront"
                  :src="getBaseUrl(form.IdCardFront)"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-camera-solid"
                  style="font-size: 30px; color: #aaa"
                ></i>
              </el-upload>
            </div>
            <div class="upload-box">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :action="uploadUrl"
                name="file"
                :headers="headers"
                :on-success="(file) => uploadSuccess(file, 'IdCardBack')"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="form.IdCardBack"
                  :src="getBaseUrl(form.IdCardBack)"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-camera-solid"
                  style="font-size: 30px; color: #aaa"
                ></i>
              </el-upload>
            </div>
            <div class="upload-box">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :action="uploadUrl"
                name="file"
                :headers="headers"
                :on-success="(file) => uploadSuccess(file, 'IdCardHandle')"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="form.IdCardHandle"
                  :src="getBaseUrl(form.IdCardHandle)"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-camera-solid"
                  style="font-size: 30px; color: #aaa"
                ></i>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="$t('pai-she-shi-li')" prop="demo">
          <div class="flex-center-start demo-line">
            <img :src="demoUrl1" alt="" />
            <img :src="demoUrl2" alt="" />
            <img :src="demoUrl3" alt="" />
          </div>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="checked"
            >{{ $t('wo-yi-yue-du-bing-tong-yi')
            }}<span>{{ $t('jie-kuan-xie-yi') }}</span></el-checkbox
          >
        </el-form-item>
        <el-form-item label="">
          <el-button>{{ $t('ti-jiao-shen-qing') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'
import headerBar from '@/components/header'
import { Field, Button, Popup, Picker } from 'vant'
import { getToken } from '@/utils/auth'
import { getRechargeInfo, addRecharge } from '@/api/user'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    headerBar,
    Field,
    Button,
    Popup,
    Picker
  },
  data() {
    return {
      demoUrl1: require('@/assets/imgs/upload-demo1.png'),
      demoUrl2: require('@/assets/imgs/upload-demo2.png'),
      demoUrl3: require('@/assets/imgs/upload-demo3.png'),
      form: {
        timeName: '',
        time: '',
        username: '',
        percent: '0.3%',
        typeName: this.$t('zhang-hu-yu-e'),
        img1: '',
        img2: '',
        img3: ''
      },
      headers: {},
      pageType: '',
      showPopup: false,
      timeList: [
        {
          value: 1,
          label: this.$t('1-tian')
        },
        {
          value: 10,
          label: this.$t('10-tian')
        },
        {
          value: 20,
          label: this.$t('20-tian')
        },
        {
          value: 30,
          label: this.$t('30-tian')
        },
        {
          value: 60,
          label: this.$t('60-tian')
        },
        {
          value: 90,
          label: this.$t('90-tian')
        },
        {
          value: 120,
          label: this.$t('120-tian')
        }
      ]
    }
  },
  computed: {
    imgType() {
      return this.$store.state.imgType
    },

    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.headers = {
      token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {},
    back() {
      this.$router.go(-1)
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    uploadSuccess(file, key) {
      this.form[key] = file.data.FileName
    },
    changeTime() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    chooseTime(e) {
      this.form.time = e.value
      this.form.timeName = e.label
      this.showPopup = false
    },
    submit() {}
  }
}
</script>